.owl-carousel {
    position: relative;
    .owl-nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        button {
            &.owl-prev,&.owl-next {
                padding: 0 15px!important;
                font-size: 150px;
                transition: font-size 0.25s ease-in-out;
                &:hover {
                    font-size: 175px;
                }
            }

            @include media-breakpoint-up(md){
                &.owl-prev,&.owl-next {
                    padding: 0 50px!important;
                    font-size: 150px;
                }   
            }
        }
    }
}