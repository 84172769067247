.main-navigation {
    // Source mixins
    // background-color: $custom-dark;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 35%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10000;
    @include media-breakpoint-up(xl) {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        background: rgb(0,0,0);
    }
    
    .main-navigation-list {
        display: none;
        
        @include media-breakpoint-up(xl) {
            display: flex;
            flex-direction: row;
            // justify-content: space-around;
            align-items: stretch;
            justify-content: flex-end;
        }

        width: 60%;
        margin: 0;
        padding: 0 10px;
        color: white;
        list-style: none;



        .main-navigation-list-item {
            display: flex;
            align-items: center;
            border-top: solid 5px #ffffff00;
            border-bottom: solid 5px #ffffff00;
            transition: border 0.3s ease-in-out ;
            font-size: large;
            letter-spacing: 0.05rem;
            margin: 0 5px;
            text-transform: uppercase;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 15px;
                height: 100%;
                width: 100%;
                color: inherit;
            }

            ::after {
                content: "";
                border-bottom: solid 5px $dlcweb-color-primary;
            }

            &:hover,&.active {
                border-bottom: solid 5px $dlcweb-color-primary;

                ::after {
                    content: "";
                    border-bottom: solid 5px $dlcweb-color-primary;
                    transform: translate(100%,0);
                }
            }
        }
    }

}

.site-name {
    // position: fixed;
    // left: 20px;
    // top: 20px;
    padding: 20px;
    z-index: 1000;
    font-size: 26px;
    font-family: Articulat;
    font-weight: 200;
    color: $dlcweb-color-primary;
    text-decoration: none;
    strong {
        font-weight: 900;
    }
    @include media-breakpoint-up(xl) {
        display: none;
    }
}

.site-logo {
    width: 180px;
    z-index: 1000;
    display: none;
    
    padding: 20px;
    .logo-mini-white, .logo-mini-black ,.logo-mini {
        display: none;
    }

    @include media-breakpoint-up(xl) {
        display: block;
    
    }
}

.menu-container {
    // position: fixed;
    // right: 20px;
    // top: 20px;
    padding: 20px;
    z-index: 100000;
    @include media-breakpoint-up(xl) {
        display: none;
    }
        
    button.menu-btn {
        background: none;
        border: none;
        color: $dlcweb-color-primary;
        margin: 5%;
        font-size: x-large;
    }
}

.back-container {
    position: fixed;
    left: 20px;
    top: 20px;
    z-index: 1000;
    @include media-breakpoint-up(xl) {
        top: calc(var(--nav-height) + 32px);
    }
}

.sidebar-navigation {
    position: fixed;
    top: 0;
    padding-top: calc(var(--nav-height) - 1px);
    right: 100%;
    width: 100vw;
    max-width: 600px;
    color: white;
    
    --secondary: #000;
    --bluesky: #00d3b8;
    
    transition: --secondary 1s, --bluesky 1s;

    transition: transform 0.5s ease-in-out;

    background: 
    linear-gradient(var(--secondary), transparent),
    linear-gradient(180deg, var(--bluesky), var(--bluesky));
    z-index: 50;

    .navigation {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        height: calc(100vh - var(--nav-height));
        .sidebar-main-navigation-logo {
            height: auto;
            width: 100%;
            max-width: 250px;
        }
        .sidebar-main-navigation {
            position: relative;
            list-style: none;
            padding: 0;
            height: 80%;
            max-height: 800px;
            display: flex;
            flex-direction: column;
            text-align: start;
            justify-content: start;
            width: 90%;
            margin-top: 5%;
            transform: translate(0,10px);
            opacity: 0;
            transition: transform 0.3s ease-in-out $nav-show-delay, opacity 0.3s ease-in-out $nav-show-delay;
            .nav-link {
                color: white;
                text-decoration: none;
                font-size: xx-large;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 3px;
                line-height: 1.3em;
                font-family: 'Articulat Bold',sans-serif;
                padding: 0 0 5px;
                &:hover {
                    text-decoration: none;
                    color: white;
                }

                &.active {
                }

                &.nav-link-small {
                    letter-spacing: 1px;
                    font-size: x-large;
                }

                &.nav-link-tree {
                    a {
                        color: white;
                        text-decoration: none;
                    }
                }
            }

            .secondary-navigation-list {
                // height: 0;
                display: none;
                overflow: hidden;
                padding-left: $menu-padding-left;
                // transition: height 1s ease-in-out;
                &.active {
                    // display: block;
                    // height: 100%;
                    li, .nav-link {
                        opacity: 1;
                        transform: translate(0,0);
                    }
                }
                
                li, .nav-link {
                    transition-delay: 0.3s;
                    transform: translateX(-100%);
                    letter-spacing: 1px;
                    font-size: x-large;
                    opacity: 0;
                    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
                }

            }

            .sidebar-navigation-item-bottom {
                position: absolute;
                bottom: 0;
            }

        }

        .navigation-language {
            position: absolute;
            bottom: 1em;
            right: 20px;
            text-align: right;
            transform: translate(0,10px);
            opacity: 0;
            transition: transform 0.3s ease-in-out $nav-show-delay, opacity 0.3s ease-in-out $nav-show-delay;
            
            select {
                background: rgba($dlcweb-color-secondary,0.3);
                color: $dlcweb-color-primary;
                border: none;
                border-radius: 1px;
                border-bottom: 2px solid white;

                &::before,&::after {
                    color: $dlcweb-color-primary;

                }

                option {
                    background: $dlcweb-color-primary;
                    color: $dlcweb-color-secondary;
                }
            }
        }
    }

    &:hover,&.active {
        --secondary: #000;
        --bluesky: #00d3b8;
    }
    
    &.active {
        transform: translate(100%,0);
        background: 
        linear-gradient(var(--secondary), transparent),
        linear-gradient(180deg, var(--bluesky), var(--bluesky));
        z-index: 50;
        .navigation {
            .sidebar-main-navigation,.navigation-language {
                transform: translate(0);
                opacity: 1;
                transition-delay: 0s;
            }
        }
    }

    
}