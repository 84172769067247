#hero {
    --nav-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-size: cover;
    // background-attachment: fixed;
    min-height: calc(100vh - var(--nav-height));
    color: $dlcweb-color-primary;
 
    position: relative;
    // padding-top: max(var(--nav-height, 100px),100px);
    // background-color: #000;

    @media (min-width: 1200px) {
        padding-top: 0;
        scroll-behavior: smooth;
        min-height: calc(100vh - var(--nav-height));
    }
    
    .logo {
        width: 100%;
        max-width: 50vw;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        .logo-full {
            // Source mixins
            display: none;
            max-height: 50vh;

            // No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
            @include media-breakpoint-up(md) {
                display: block;
            }
            
        }

        .logo-mini {
            max-height: 50vh;

            max-width: 300px;

            // Source mixins

            // No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
            @include media-breakpoint-up(md) {
                display: none;
            }
            
        }

    }
}