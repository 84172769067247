&.is-categories {
    color: $dlcweb-color-primary;
    background: $dlcweb-color-secondary;
    align-items: flex-start;
    
    .categories {
        opacity: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0;
        padding-top: max(var(--nav-height, 100px),100px);
        @include media-breakpoint-up(xl) {
            padding-top: 0;        
        }

        .category-container {
            overflow: hidden;
            position: relative;

            .arrow {
                --arrow-px: 10px;

                position: absolute;
                left: 50%;
                bottom: 15%;
                border: solid white;
                border-width: 0 var(--arrow-px,15px) var(--arrow-px,15px) 0;
                display: inline-block;
                padding: var(--arrow-px,15px);
                transform: translate    (50% 0), rotate(-135deg);
            }

            @import './utilities/banner';

            .container {
                position: relative;
                z-index: 1;


                .category-title {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    text-align: center;
                    text-transform: uppercase;
                    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
                    font-weight: bold;
                    letter-spacing: 2px;
                    margin-bottom: 15px;
                }

                .category-banner {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    z-index: 3;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 31%;
                        left: -10%;
                        width: 120%;
                        height: 35%;
                        background: $dlcweb-text-bluesky-800;
                        opacity: 100%;
                        z-index: 0;
                    }

                    .category-title {
                        position: relative;
                        width: 50%;

                        text-align: center;
                        text-transform: uppercase;
                        font-weight: bold;
                        letter-spacing: 2px;
                        margin-bottom: 0px;
                    }

                    img {
                        max-width: 50%;
                        z-index: 2;
                        margin-right: -1em;

                    }
                }


                .category-description {
                    flex-direction: column;
                    padding: 1.5em 0 2em;
                    transform: translateY(100%);
                    width: 100%;
                    p {
                        text-align: justify;
                    }
                    a {
                        text-align: right;
                        width: 100%;
                    }
                }
            }
        }

    }

    .online-quote-section {
        background: $dlcweb-color-secondary;
        text-align: center;
        padding: 35px 0;
        width: 100%;
        color: white;
    }
}