&.is-products {
    color: $dlcweb-color-primary;
    background: $dlcweb-color-secondary;
    .products {
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        // align-items: flex-start;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        padding: 0;
        padding-top: max(var(--nav-height, 100px),100px);
        @include media-breakpoint-up(xl) {
            padding-top: 0;        
            min-height: calc(100vh - var(--nav-height));
        }

        .product-container {
            background: url("/img/polar-lights-full-bg.webp");
            background-position: 50% 50%;
            background-size: cover;
            overflow: hidden;
            position: relative;
            height: 100%;
            padding: 10px;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, #00000050, #000000);
                opacity: 85%;
            }

            .arrow {
                --arrow-px: 10px;

                position: absolute;
                left: 50%;
                bottom: 15%;
                border: solid white;
                border-width: 0 var(--arrow-px,15px) var(--arrow-px,15px) 0;
                display: inline-block;
                padding: var(--arrow-px,15px);
                transform: translate(50% 0), rotate(-135deg);
            }

            .container {
                position: relative;
                z-index: 1;
                height: 100%;
                min-height: 100px;
                @include media-breakpoint-up(md) {
                    min-height: 200px;
                }
    
                @include media-breakpoint-up(xl) {
                    min-height: 250px;
                }
                .product-title {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    text-align: center;
                    text-transform: uppercase;
                    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
                    font-weight: bold;
                    letter-spacing: 2px;
                    margin-bottom: 15px;
                }

                .product-description {
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
                    display: flex;
                    flex-direction: column;
                    padding: 1.5em 0 2em;
                    transform: translateY(100%);
                    
                    width: 100%;

                    p {
                        text-align: left;
                    }

                    a {
                        text-align: right;
                        width: 100%;
                        
                        &::first-letter{
                            text-transform: uppercase;
                        }
                    }
                }
            }
            &:hover {
                .container {
                    .product-title {
                        opacity: 0;
                        transform: translate(0, 100%);
                    }

                    .product-description {
                        opacity: 1;
                        transform: translateY(0);

                        
                    }
                }
            }


        }

        .title {
            background: linear-gradient(90deg, #003a36, #00A297);
            padding: 7px 0 2px 20px;
            border-radius: 0 50px 50px 0;
            text-transform: uppercase;
            font-weight: bold;
            width: min(50%, 260px);
            letter-spacing: 1px;
            margin: 10px 0;
            min-width: 250px;
            color: $dlcweb-color-primary;
        }

        .btn {
            &::first-letter{
                text-transform: uppercase;
            }
        }
    }
}