footer {
    font-size: 0.85rem;
    color: $dlcweb-color-primary;
    width: 100%;
    background: $dlcweb-color-secondary;
    border-bottom: 2px solid black;
    a {
        transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
        color: inherit;
        text-decoration: none;
        &::first-letter {
            text-transform: uppercase;
        }
        &:hover {
            transform: translateY(-3px) scale(1.05);
            color: $dlcweb-color-bluesky;
            text-decoration: none;

        }
    }

    .logo {
        width: 100%;
        max-width: 50vw;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 20px auto;
        .logo-full {
            // Source mixins
            max-height: 50vh;
            
            display: block;
            // No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
            @include media-breakpoint-up(md) {
                display: none;
            }
            
        }

        .logo-mini {
            max-height: 50vh;

            max-width: 300px;

            // Source mixins
            display: none;


            
        }

    }

    .contact-data {
        font-size: 1rem;
        margin-bottom: 20px;
        ul {
            list-style: none;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            margin: 0 auto;
            padding: 0;
            max-width: 50%;
            @include media-breakpoint-up(md) {
                max-width: 80%;
                columns: 2;
            }
            @include media-breakpoint-up(lg) {
                max-width: 50%;
            }
            li {
                width: 100%;   
                text-align: center;
                max-width: 200px;
                margin: 0 auto;
                a {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    text-decoration: none;
                    i {
                        margin-right: 10px;
                        transition: transform 0.3s ease-in-out;
                        transition-delay: 0.15s;
                    }
                    &:hover {
                        i {
                            transform: rotate(5deg);
                        }
                    }

                }
            }

        }
        
        @include media-breakpoint-up(md) {
            font-size: 1.2rem;
        }
    }

    .nav-footer {
        font-size: 1.2rem;
        margin-bottom: 20px;
        ul {
            list-style: none;
            columns: 2;
            
            @include media-breakpoint-up(md) {
                columns: 3;
            }

            @include media-breakpoint-up(lg) {
                columns: 4;
            }
            padding: 0;
            margin: 0;

            li {
                text-align: center;
            }
        }
    }

    .copywright,.tos {
        text-align: center;
        margin-bottom: 10px;
        a {
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
}