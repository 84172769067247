// Surface Pro
$device-silver: #eee;
$device-silver-dark: darken($device-silver, 15%);
$device-silver-panel: #0d0d0d;

.device-surface-pro {
  height: 394px;
  width: 561px;

  .device-frame {
    background: $device-silver-panel;
    border-radius: 10px;
    box-shadow: inset 0 0 0 2px $device-silver-dark;
    height: 394px;
    margin: 0 auto;
    padding: 26px 24px;
    width: 561px;
  }

  // 2736-by-1824-pixel resolution
  .device-content {
    border: 2px solid lighten($device-silver-panel, 2%);
    border-radius: 2px;
    height: 342px;
    width: 513px;
  }

  .device-btns {
    &::after,
    &::before {
      background: $device-silver-dark;
      content: "";
      height: 2px;
      position: absolute;
      top: -2px;
    }

    &::after {
      left: 48px;
      width: 26px;
    }
    &::before {
      left: 94px;
      width: 48px;
    }
  }

  .device-sensors {
    background: #333;
    border-radius: 50%;
    height: 6px;
    left: 50%;
    margin-left: -3px;
    margin-top: -3px;
    position: absolute;
    top: 14px;
    width: 6px;
  }
}