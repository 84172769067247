&.is-cotiza {
    flex-direction: column;

    position: relative;

    &:after{
      content:"";
      position:fixed; /* stretch a fixed position to the whole screen */
      top:0;
      height:100vh; /* fix for mobile browser address bar appearing disappearing */
      left:0;
      right:0;
      z-index:-1; /* needed to keep in the background */
      background: url("/img/polar-lights-bg.webp") center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
    }

    // Repeated Every Section -- !! KEEP DRY !!
    .cotiza {
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        // align-items: flex-start;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        padding: 0;
        background: linear-gradient(0deg, $dlcweb-color-secondary, transparent, $dlcweb-color-secondary);
        padding-top: max(var(--nav-height, 100px),100px);
        @include media-breakpoint-up(xl) {
            padding-top: 0;        
            min-height: calc(100vh - var(--nav-height));
        }


            
        
    }

    form {
        .card {
            color: #000;
            .section {
                text-align: center;
                border-bottom: 1px solid #000;
                &:not(:first-child) {
                    padding-top: 1em;
                }
                padding-bottom: 1em;
            }

            .question {
                text-align: center;
                font-size: x-large;
                margin-bottom: 0;
            }

            .custom-control {
                padding-left: 0;
            }

            .custom-control-label {
                padding-left: 0;
                small, .small {
                    font-weight: 300;
                }
            }

            .custom-control-inline {
                margin: 0 0.75em;
            }

            .custom-radio {
                input[type=radio],input[type=checkbox] {
                    &:checked,&:hover {
                        ~ .custom-control-label {
                          color: #009B90;  
                        }                        
                    }

                    &:checked{
                        ~ .custom-control-label {
                          transform: translateY(-3px) scale(1.01);
                        }                        
                    }
                }

                .custom-control-label {
                    transition: color 0.15s ease-in-out, transform 0.3s ease-in-out;
                    font-size: xx-large;

                    &::before {
                        content: none;
                    }

                    &::after {
                        content: none;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    strong {
    
                        small {
                            display: inline;
                            font-size: x-small;
                            margin-right: 4px;
                        }

                    }

                    small {
                        font-size: small;
                        &.block {
                            display: block;
                            text-align: center;
                            margin-top: -10px;
                        }
                    }

                }
            }

            .disclaimer, .complete-form-disclaimer{
                small, .small {
                    font-weight: 300;
                }
            }

            .submit-btn {
                cursor: pointer;
                display: block;
                width: 100%;
                padding: 0.5rem 1rem;
                font-size: 1.125rem;
                line-height: 1.5;
                border-radius: 0.3rem;
                color: #fff;
                background-color: #00d3b8;
                border-color: #00d3b8;
                text-align: center;
                vertical-align: middle;
                user-select: none;
                font-weight: 400;
                text-decoration: none;

                &:hover {
                    color: #fff;
                    background-color: #00ad97;
                    border-color: #00ad97;
                }
            }
        }
    }

    @media (min-width: 400px) {
        &::after {
            background-image: url("/img/polar-lights-full-bg.webp");
        } 
    }

    
    @include media-breakpoint-up(md){
        &::after {
            background-size: auto;
            background-image: url("/img/moon-landing.webp");
            background-position: 30% -10%;
        }    
    }

    @include media-breakpoint-up(lg){
        &::after {
            background-position: 10% -10%;
        }
    }
    
    @include media-breakpoint-up(xl){
        &::after {
            background-position: -25% -10%;
        }
    }

    @media (min-width: 1700px) {
        &::after {
            background-position: 200px -10%;
        }
    }
}