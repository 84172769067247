
&.is-contact {
    color: white!important;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    text-align: left;
    position: relative;

    &:after{
      content:"";
      position:fixed; /* stretch a fixed position to the whole screen */
      top:0;
      height:100vh; /* fix for mobile browser address bar appearing disappearing */
      left:0;
      right:0;
      z-index:-1; /* needed to keep in the background */
      background: url("/img/polar-lights-bg.webp") center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;

    }

    .card {
        color: #000;
    }

    .contact-section {
        background: linear-gradient(0deg, $dlcweb-color-secondary, transparent, $dlcweb-color-secondary);
        width: 100%;
        min-height: calc(100vh - var(--nav-height));
        // .contact-paragraph {
            // line-height: 2em;
        // }
        padding-top: max(var(--nav-height, 100px),100px);
        @include media-breakpoint-up(xl) {
            padding-top: 0;        
        }
    }

    .card-header {
        
        padding-bottom: 0;
        text-align: center;
        border-bottom: none;
        .heading {
            color: $dlcweb-text-bluesky-900;
            font-weight: bolder;
            margin: 0;
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .form-group {
        position: relative;
        padding-top: 16px;
        .input-label {
            text-transform: capitalize;
            transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
            position: absolute;
            left: 0;
            top: 16px;
            padding: 0.375rem 0.75rem;
            pointer-events: none;
            border-radius: 15px;
        }
        
        input, textarea, select {

            &.form-control {
                border-radius: 18px;
                background-color: #c8c8c8;
                &::placeholder {
                    display: none;
                }
            }
        }
        
        input.form-control::placeholder, textarea.form-control::placeholder {
            color: transparent;
        }
        
        input.form-control, textarea.form-control {
            &:not(:placeholder-shown) ~ .input-label {
                transform: translateY(-100%) scale(0.95);
                padding-top: 0;
                padding-bottom: 0;
                // background-color: #f8f9fa;
            }
        }

        &:focus-within {
            .input-label {
                transform: translateY(-100%) scale(0.95);
                padding-top: 0;
                padding-bottom: 0;
                // background-color: #f8f9fa;
            }
        }

        .select-label {
            padding: 0.375rem 0.75rem;
        }
    }

    @include media-breakpoint-up(sm) {
        &:after{
            background-image: url("/img/polar-lights-full-bg.webp");
        }
    }
    
    @include media-breakpoint-up(md){
        &::after {
            background-size: auto;
            background-image: url("/img/moon-landing.webp");
            background-position: 30% -10%;
        }    
    }

    @include media-breakpoint-up(lg){
        &::after {
            background-position: 10% -10%;
        }
    }
    
    @include media-breakpoint-up(xl){
        &::after {
            background-position: -25% -10%;
        }
    }

    @media (min-width: 1700px) {
        &::after {
            background-position: 200px -10%;
        }
    }
}