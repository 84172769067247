$barba-js-timing:1s;

.home-once-active,
.home-leave-active,
.home-enter-active {
  transition: opacity $barba-js-timing ease-in-out ;

  .page-title {
    transition: transform $barba-js-timing ease-in-out ;
  }

  .section > * , .heading-container {
    transition: transform $barba-js-timing ease-in-out,
                opacity $barba-js-timing ease-in-out ;
                
  }
}


.home-leave,
.home-once-to,
.home-enter-to {
    opacity: 1;

    .page-title {
      transform: translateX(0%);
    }

    .section > * , .heading-container {
      opacity: 1;
      transform: translateY(0px);
    }
}

.home-enter,
.home-once,
.home-leave-to {
  
  opacity: 0;
  .page-title {
      transform: translateX(-110%);
  }
  .section > * , .heading-container {
    opacity: 0;
    transform: translateY(50px);
  }

  
}



.home-once-active {
  transition: opacity 1.5s ease-in-out ;

      .heading-container {
          transition: transform $barba-js-timing ease-in-out 0s,
                      opacity $barba-js-timing ease-in-out 0s ;
                      
      }
      .logo {
          transition: transform $barba-js-timing ease-in-out 0.3s,
                      opacity $barba-js-timing ease-in-out 0.3s ;
                      
      }
      .heading-container-text {
          transition: transform $barba-js-timing ease-in-out 0.6s,
                      opacity $barba-js-timing ease-in-out 0.6s ;
                      
      }
  }