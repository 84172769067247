@import 'buttons';

hr {
    margin: 0.5rem 0;
    border: solid 1px $custom-dark;
}

.dark {
    hr {
        border: solid 1px white;
    }
}

label:not(.form-check-label):not(.custom-file-label) {
    font-weight: initial;
}

@import 'custom-colors';