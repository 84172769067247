&.is-home {
    flex-direction: column;
    
    color: $dlcweb-color-primary;
    scroll-snap-type: y mandatory;
    
    position: relative;

    &:after{
      content:"";
      position:fixed; /* stretch a fixed position to the whole screen */
      top:0;
      height:100vh; /* fix for mobile browser address bar appearing disappearing */
      left:0;
      right:0;
      z-index:-1; /* needed to keep in the background */
      background: url("/img/polar-lights-bg.webp") center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }

    .hero-section {
        // background-color: $dlcweb-color-secondary;
        background: linear-gradient(0deg, $dlcweb-color-secondary 0%, $dlcweb-color-secondary 10%, transparent 50%, $dlcweb-color-secondary 90%, $dlcweb-color-secondary 100%);
        height: 100%;
        width: 100%;
        min-height: calc(100vh - var(--nav-height));

        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-bottom: min(var(--nav-height),20%);

        h1 {
            font-weight: 300;
            font-size:2.3em;
        }
        padding-top: max(var(--nav-height, 100px),100px);
        @include media-breakpoint-up(xl) {
            padding-top: 0;        
        }
    }

    .heading-container, .heading-text-container {
        margin: 0 20px 30px;
        text-align: center;
        color: $dlcweb-color-primary;

          h1,h2,h3,h4,h5,h6 {
            margin-bottom: 1em;
          }
        
          a {
            text-decoration: none;
          }

        .cta-box {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            $displacement: 15px;
            font-size: 1rem;
            
            .cta-reveal {
                transform: translateX($displacement);
                font-style: italic;
                transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
            }

            i { 
                // display: flex;
                // justify-content: center;
                // align-items: center;
                opacity: 0;
                transform: translateX(- $displacement);
                margin-left: 10px;
                transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out 0.1s;
            }

            &:hover {
                .cta-reveal {
                    transform: translateX(0px);
                }
                i {
                    opacity: 1;
                    transform: translateX(0);
                    
                }   
            }
        }
    }

    
    .logo {
        margin: 0 auto 30px;

        svg {
            overflow: visible;
        }
    }

    .web-design-section {
        height: 100%;
        width: 100%;
        background-color: $dlcweb-color-secondary;
        background: linear-gradient(0deg, $dlcweb-color-secondary 10%, $dlcweb-color-secondary 20%, rgba($dlcweb-color-secondary,50%) 50%, $dlcweb-color-secondary 80%, $dlcweb-color-secondary 100%);
        text-align: center;
        img {
            padding: 1em 0;
            max-width: 100%;
            height: auto;
            margin: 0 auto;

            &.float {
              animation: float 6s ease-out infinite;
              transform: translateY(-15px);
            }

            @keyframes float {
              //lateral
              25% {
                transform: translateX(10px) translateY(0px) ;
              }
              //top
              50% {
                transform: translateY(-15px) translateX(0px);
              }
              //lateral
              75% {
                transform: translateX(-10px) translateY(0px);
              }
              
            }
        }
    }

    .benefits-section {
      $transparency-fade: 10%;
      background-color: #00756d90;
      height: 100%;
      width: 100%;
      // background: linear-gradient(225deg, #080808, transparent), linear-gradient(45deg, black, transparent), black;
      padding: 7em 0;
      position: relative;
      .benefits-title-container {
        margin-bottom: 2rem;
      }
      .benefits-container {
        list-style: none;
        padding: 0;

        .item {
          margin-bottom: 35px;

          img {
            margin-bottom: 15px;
          }
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, #000 0%, transparent calc($transparency-fade), transparent calc(100% - $transparency-fade), #000 100%);
      }
    }

    .service-description-section {
      .col {
        position: relative;
      }
      .img-float {
        position: sticky;
        top: 25vh;
        left: 0;
        margin-bottom: 50px;
      }
    }

    .guarantees-section {
      height: 100%;
      width: 100%;
      background: $dlcweb-color-secondary;
      text-align: center;
      padding-bottom: 4em;
      &:last-of-type {
        padding-bottom: 1em;
      }


      ul, .list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 5em;

        &:last-of-type {
          margin: 0 ;
        }

        padding: 0;
        --elements-counter:1;
        li {
          display: flex;
          list-style: none;
          margin: 0 auto ;
          padding: 1em;
          width: calc(100%/var(--elements-counter,1));
          font-size: 0.8rem;
          
          .list-content {
            border-radius: 15px;
            box-shadow: 0px 0px 10px var(--dlcweb-color-bluesky, $dlcweb-color-bluesky);
            display: flex;
            flex-direction: column;
            padding: 1.5em 0.8em 1.3em;
            width: 100%;
            transition: box-shadow 0.3s ease-in-out;
          }
          i {
            flex: 1 0 auto;

            font-size: 3rem;
            margin: 0;
            transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
          }
          
          &:hover {
            .list-content {
              box-shadow: 0px 0px 20px var(--dlcweb-color-bluesky, $dlcweb-color-bluesky);
            }
            
            i {
              color: $dlcweb-color-bluesky;
              transform: rotate(5deg) scale(1.05) translateY(-5px);
            }
          }
          
          p {
            margin: 0;
            margin-top: 10px;
            font-size: 1.4em;
          }
        }

        @media screen and (min-width: 300px) {
          --elements-counter: 2;
        }

        @include media-breakpoint-up(lg) {
          --elements-counter: 3;
        }

        @include media-breakpoint-up(xl) {
          --elements-counter: 5;
          margin-bottom: 3em;
        }
      }
      
      .heading-text-container {
        @include media-breakpoint-up(md) {
          h3 {
            text-align: left;
            margin-left: 1em;
          }

        }

        @include media-breakpoint-up(lg) {
          margin: 0 4em 3em;
        }
        
        @include media-breakpoint-up(xl) {
          margin: 0 6em 3em;
        }
      }
    }

    .showcase-section {
        height: 100%;
        width: 100%;
        padding: 4rem 0;
        position: relative;
        .container {
          position: absolute;
        }
        img {
            padding:0;
        }
        background: $dlcweb-color-secondary;

        &.owl-carousel {
          .container {
            position: relative;
          }
        }
    }

    .service-description {
        background: $dlcweb-color-secondary;
        
        .item {
          p {
            font-size: 0.8rem;
          }
        }
    }

    .online-quote-section {
      background: linear-gradient(0deg, black 15%, rgba(0,0,0,0.60) 50%, black 85%);
      text-align: center;
      padding: 35px 0 5em;
      width: 100%;
      color: white;
      .item {
        p {
          font-size: 0.8rem;
        }
      }
    }

    .cotiza {
      z-index: 10;
      margin: 0;
      padding: 0;
    }

    @media (min-width: 400px) {
        // background-size: cover;
        
    &:after{
      background: url("/img/polar-lights-full-bg.webp") center center;
    }

        .hero-section {
            h1 {
                font-size:3.5em;
            }
        }
    }


    @media (min-width: 768px) {
        .logo {
            max-width: min(90%, 600px);
        }   
        .hero-section {
            h1 {
                font-size:4em;
            }
        } 
    }

    @media (min-width: 1200px) {
        .hero-section {
            // min-height: calc(50vh - var(--nav-height));
            h1 {
                font-size:4.2em;
            }
            
        }    
    }

    h1,h2,h3,h4,h5,h6,p {
      &::first-letter{
        text-transform: uppercase;
      }
    }
    

}

/* PROGRESS BAR */


.progress {
    $gap: 20px;
    $line-height: 20px;
    $bullet-radius: 5px;
    $line-thick: 2px;
    $strip-color: #333;
    $next-color: #666;
    $current-color: #333;
    
    $prev-color: #333;
    
  
    display: inline-flex;
    height: 100%;
    padding: 0; 
    @include media-breakpoint-up(md) { 
      padding: 5vh 10% 5vh 5%; 
      font-size: 1rem;
    }
    @include media-breakpoint-down(md) { 
      h4 {
        font-size: 0.9rem;
      }
    }
    background-color: transparent;
    line-height: 1.1rem;
    > .progress-container {
      display: flex;
      flex-direction: column;
      color: $prev-color;
  
      &.left {
        padding-right: $gap;
        text-align: right;
        padding-left: 0;

        // Line
        .item{
          @include media-breakpoint-down(sm) {
            padding-left: 15px;
          }


          &:last-of-type:after {
            display: none;
          }
          
          &:after {
            content: "";
            background: fade_out($strip-color, .9); //rgba(0, 0, 0, 0.6);
            border-radius: 2px;
            position: absolute;
            right: -$gap;
            top: math.div($line-height,2);
            height: 101%;
            width: 1px;
            transform: translateX(50%);
          }
        }
      }
  
      &.right {
        padding-left: $gap;
        display: block;
        .item{        
          &.prev {          
              &:after {
                transition: none;
              }
          }
          
          &.current
          // ,&:hover
           {
            color: $current-color;
            font-weight: bold;
  
            &:before {
              background: $current-color;
              padding: $bullet-radius * 2;
              transition: all 0.2s .15s cubic-bezier(0.175, 0.885, 0.32, 2);
            }
  
            &:after {
              height: 0%;
              transition: height .2s ease-out;
            }

            .content {
                p {
                  opacity: 1;
                }
            }
            ~ .item{
              color: $next-color;
              
              &:before {
                background: $next-color;
                padding: $bullet-radius * 0.5;
              }
  
              &:after {
                height: 0%;
                transition: none;
              }
            }
          }
  
          // Dot
          &:before {
            content: "";
            background: $strip-color;
            padding: $bullet-radius;
            border-radius: 50%;
            position: absolute;
            left: -$gap;
            top: math.div($line-height,2);
            transform: translateX(-50%) translateY(-50%);
            transition: padding 0.2s ease;
          }
  
          // Line
          &:after {
            content: "";
            background: $strip-color; //rgba(0, 0, 0, 0.6);
            border-radius: 2px;
            position: absolute;
            left: -$gap;
            top: math.div($line-height,2);
            height: 101%;
            width: $line-thick;
            transform: translateX(-50%);
            transition: height 0.2s ease;
          }
        }
      }
  
      .item{
        flex: 1;
        min-height: var(--min-height-items);
        //outline: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        line-height: $line-height;
        cursor: default;
        list-style: none;
        height: 100%;
        padding:  0 0 25px;
        &::first-letter{
          text-transform: uppercase;
        }
        .content {
            padding-top: 0px;

            @include media-breakpoint-down(sm) {
              padding-right: 15px;
            }

            h1,h2,h3,h4,h5,h6 {
              font-weight: bold;
              margin-bottom: 0.5em;
            }
            p {
              transition: opacity 0.3s ease-in-out;
              opacity: 0;
              padding-bottom: 15px;
              // font-size: 0.95rem;
              font-weight: 300;
            }

        }
        
        &:last-of-type {
          flex-grow: 1;
          &:after {
            display: none;
          }
        }
      }
    }
}

.progress.dark {  
  
  
  $gap: 20px;
  $line-height: 20px;
  $bullet-radius: 5px;
  $line-thick: 2px;
  $strip-color: lightgray;
  $pending-color: #666;

  display: inline-flex;
  height: 100%;
  width: 100%;

  > .progress-container {
    display: flex;
    flex-direction: column;
    color: #ccc;

    &.left {
      padding-right: $gap;
      text-align: right;
      // Line
      .item{
        padding-top: 0;

        &:last-of-type:after {
          display: none;
        }
        
        &:after {
          content: "";
          background: fade_out($strip-color, .5); //rgba(211, 211, 211, 0.5);
          border-radius: 2px;
          position: absolute;
          right: -$gap;
          top: math.div($line-height,2);
          height: 101%;
          width: 1px;
          transform: translateX(50%);
        }
      }
    }

    &.right {
      padding-left: $gap;

      .item{        
        &.prev {          
            &:after {
              transition: none;
            }
        }
        
        &.current
        // ,&:hover 
        {
          color: white!important;
          font-weight: bold;

          &:before {
            background: white;
            padding: $bullet-radius * 2;
            transition: all 0.2s .15s cubic-bezier(0.175, 0.885, 0.32, 2);
          }

          &:after {
            height: 0%;
            transition: height .2s ease-out;
          }

          ~ .item{
            color: $pending-color;
            
            &:before {
              background: $pending-color;
              padding: $bullet-radius * 0.5;
            }

            &:after {
              height: 0%;
              transition: none;
            }
          }
        }

        // Dot
        &:before {
          content: "";
          background: $strip-color;
          padding: $bullet-radius;
          border-radius: 50%;
          position: absolute;
          left: -$gap;
          top: math.div($line-height,2);
          transform: translateX(-50%) translateY(-50%);
          transition: padding 0.2s ease;
        }

        // Line
        &:after {
          content: "";
          background: $strip-color; //rgba(0, 0, 0, 0.6);
          border-radius: 2px;
          position: absolute;
          left: -$gap;
          top: math.div($line-height,2);
          height: 101%;
          width: $line-thick;
          transform: translateX(-50%);
          transition: height 0.2s ease;
        }
      }
    }

  }
}

.done.current {  
  color: #62af0b !important;
    
  &:before {
    background: #62af0b !important;
  }
}

.dark .done.current {  
  color: lightgreen !important;
    
  &:before {
    background: lightgreen !important;
  }
}
  
  
/* PARTICLES JS */

#particles-js {
  position: relative;
  height: var(--min-particles-height, 300px);

  .content {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    .container {
      padding: 5em 0;

    }
  }
}

.particles-js-canvas-el {
  
  z-index: 0;

}
  
  
  
  
  
  
  
  
  
  
  
  