$transition-timer: 0.5s;

.public-body {
    font-size: 1.25rem;
    text-align: justify;
    position: relative;
    min-height: calc(100vh - var(--nav-height));
    background-color: $dlcweb-color-secondary;
    font-family: 'Articulat','sans serif';
    font-weight: 300;
    
    @import 'public/navigation';

    .navigation-separator {
        height: max(var(--nav-height, 100px), 100px);
        background-color: #000;
    }

    #hero {
        .page-title {
            background: linear-gradient(90deg, #003a36, #00A297);
            padding: 7px 0 2px 20px;
            border-radius: 0 50px 50px 0;
            text-transform: uppercase;
            font-weight: bold;
            width: min(80%, 300px);
            letter-spacing: 1px;
            margin: 10px 0;
            min-width: 250px;
            color: $dlcweb-color-primary;
            z-index: 10;
            text-align: left;
        }
        

        --barba-js-timing: $barba-js-timing;
        --barba-with-stagger: calc(var(--barba-js-timing,0s) + var(--stagger-timer-ref,0s));        
        @import 'public/home';
        @import 'public/about';
        @import 'public/blog';
        @import 'public/products';
        @import 'public/categories';
        @import 'public/contact';
        @import 'public/cotiza';
        @import 'public/portfolio';

        @import 'public/privacy-policy';
        
        @import 'public/footer';
    }
}

@import 'admin/login';

//DLCweb Responsive SVG logos
@import 'logos';

@import 'public/utilities/general';

@import 'public/hero';
@import 'custom-barba';
