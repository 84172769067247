&.is-about {

    align-items: flex-start;
    color: $dlcweb-color-primary;
    background: $dlcweb-color-secondary;
    .about {
        opacity: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        // min-height: 100vh;
        height: 100%;
        padding: 0;
        overflow: hidden;
        padding-top: max(var(--nav-height, 100px),100px);
        @include media-breakpoint-up(xl) {
            padding-top: 0;        
        }
        
        .title {
            background: linear-gradient(90deg, #003a36, #00A297);
            padding: 7px 0 2px 20px;
            border-radius: 0 50px 50px 0;
            text-transform: uppercase;
            font-weight: bold;
            width: min(50%, 260px);
            letter-spacing: 1px;
            margin: 10px 0;
            min-width: 250px;
            color: $dlcweb-color-primary;
        }

        @import './utilities/banner';
    }

    .online-quote-section {
        background: linear-gradient(0deg, black 15%, rgba(0,0,0,0.60) 50%, black 85%);
        text-align: center;
        padding: 35px 0 5em;
        width: 100%;
        color: white;
        .item {
          p {
            font-size: 0.8rem;
          }
        }
      }
}