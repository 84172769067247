.is-login {
    min-height: 100vh!important;
    align-items: stretch!important;
    position: relative;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background-image: url("/img/moon-landing.webp");
        background-position: 50% 15%;
        object-position: center;
        pointer-events: none;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: linear-gradient(135deg, rgba($dlcweb-color-blue,0.05), $dlcweb-color-blue);
        pointer-events: none;
    }
}

.is-forgotten-password {
    background-image: url("/img/moon-landing.webp");
    min-height: 100vh!important;
    align-items: stretch!important;
}