.banner {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100px;
    margin: 3em 0;
    color: $dlcweb-color-primary;
    z-index: 3;
    &::before {
        content: '';
        z-index: 0;
        position: absolute;
        top: 0%;
        left: -10%;
        width: 120%;
        height: 100%;
        background-color: darken($dlcweb-color-bluesky, 11%);

    }


    p {
        margin: 0;
    }

    .thumbnail {
        position: absolute;
        left: max(5%,5px);
        top: 0;
        max-width: 250px;
        img {
            // transform: translateY(-25%);
            margin: -3em 0;
        }

        &.no-claim {
            left: 0%;
            top: 0;
            max-width: 100%;

            @include media-breakpoint-up(md) {
                max-width: min(1000px, 80%);

            }
        }
    }

    .claim {
        font-weight: bold;
        max-width: min(50%,200px);
        text-align: center;
        margin: 0;
        padding: 0 10px 0 0;
        z-index: 1;
    }

    @media (min-width: 576px) {
        justify-content: center;
        .thumbnail {
            position: relative;
            left: 0;
            top: 0;
            max-width: 500px;
        }
        .claim {
            max-width: 250px;
            text-align: end;
            margin: 0;
            padding: 0 2em 0 0;
        }

    }
    

    &.skewed {
        min-height: 125px;
        margin-bottom: 4.5em;
        &::before {
            background: linear-gradient(90deg, darken($dlcweb-color-bluesky, 10%), darken($dlcweb-color-bluesky, 20%));
            clip-path: polygon(0 60%, 100% 0, 100% 100%, 0% 100%);
        }
        
    }

    .carousel {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-height: 100px;
        margin: 3em 0;
        color: $dlcweb-color-primary;
        z-index: 3;
    }
}