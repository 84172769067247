

.color-blue {
    color: $dlcweb-color-blue;
}

.color-bluesky {
    color: $dlcweb-color-bluesky;
}

.color-purple {
    color: $dlcweb-color-purple;

}