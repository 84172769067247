&.is-privacy-policy {
    color: white;

    width: 100%;
    padding: 0 25px;
    padding-top: 120px;
    justify-content: space-between;
    align-items: stretch;
    @include media-breakpoint-up(xl) {
        padding: 25px 3rem 0 calc(3rem + 20px) ;
    }

    h1 {
        font-size: 1.2rem;
    }

    h2 {
        font-size: 1.1rem;
    }

    h3,h4,h5,h6{
        font-size: 1rem;
    }

    p {
        text-align: justify;
    }

    .icon-link {
        letter-spacing: 0;
        font-family: "Urbanist", "sans serif";
        border-bottom: solid #1A1718 2px;
        transition: border 0.3s ease-in-out;
        margin-bottom: 0.5em;
    }
}