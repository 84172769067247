

.logo {


    &.logo-full {
        &.logo-black {
            .cls-3 {
                fill: url(#logo-black-degradado-2);
            }
            .cls-4 {
                fill: url(#logo-black-degradado-1);
            }
        }

        
        &.logo-white {
            .cls-3 {
                fill: #fff;
                fill: url(#logo-white-degradado-2);
            }
            .cls-4 {
                fill: #fff;
                fill: url(#logo-white-degradado-1);
            }
        }
    }

    &.logo-mini {
        &.logo-black {
            .cls-3 {
                fill: url(#logo-mini-D)
            }
            .cls-4 {
                fill: url(#logo-mini-L)
            }
        }

        &.logo-white {
            .cls-3 {
                fill: #fff;
                fill: url(#logo-mini-white-degradado-2);
            }
    
            .cls-4 {
                fill: #fff;
                fill: url(#logo-mini-white-degradado-1);
            }
        }
    }


    &.logo-black {
        .cls-1 {
            fill: #000;
        }

    }

    &.logo-white {
        .cls-1 {
            fill: #FFF;
        }
    }

    .cls-2 {
        fill: #4808f4
    }
}