body {
    margin: 0;
    padding: 0;
    border: none;
    font-weight: 300;
}

strong {
    font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: 1px;
}