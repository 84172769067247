&.is-portfolio {
    color: $dlcweb-color-primary;
    background: $dlcweb-color-secondary;
    .portfolio {
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        // align-items: flex-start;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        padding: 0;
        padding-top: max(var(--nav-height, 100px),100px);
        @include media-breakpoint-up(xl) {
            padding-top: 0;        
            min-height: calc(100vh - var(--nav-height));
        }

        .portfolio-list {
            padding: 0;
            margin: 0;
            list-style: none;
            .portfolio-item {
                position: relative;
                overflow: hidden;
                .mockup-container {
                    position: relative;
                    .container {
                        
                        margin: -50px auto -100px auto;
                            
                        @media (min-width: 600px) {
                            margin: -20px auto -100px auto;
                        }

                        @media (min-width: 840px) {
                            margin: 0 auto -4.5em auto;
                        

                            .device {
                                transform: scale(0.6);
                                left: 0;
                            }
                        }
                    }
                }

                @keyframes space {

                    0% {
                        transform: rotate(0deg) translate(0,0) scale(1);
                    }

                    10% {
                        transform: rotate(10deg) translate(2px,10px)  scale(1.03);
                    }

                    20% {
                        transform: rotate(40deg) translate(5px,12px) scale(1.06);
                    }

                    30% {
                        transform: rotate(80deg) translate(7px,6px) scale(1.05);
                    }

                    40% {
                        transform: rotate(140deg) translate(5px,2px) scale(1.03);
                    }

                    50% {
                        transform: rotate(180deg) translate(3px,0px) scale(1);
                    }
                    
                    60% {
                        transform: rotate(200deg) translate(-2px,-2px) scale(0.99);

                    }

                    70% {
                        transform: rotate(240deg) translate(-2px,-6px) scale(0.98);

                    }
                    
                    80% {
                        transform: rotate(290deg) translate(-2px,-10px) scale(0.98);

                    }

                    90% {
                        transform: rotate(330deg) translate(-2px,-4px) scale(0.99);
                    }

                    100% {
                        transform: rotate(360deg) translate(0px,0px) scale(1);
                    }
                }
                .background-asset {
                    position: absolute;
                    top: 20px;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    @media (min-width: 840px) {
                        top: 0;
                    }
                    text-align: center;
                    animation: space 100s linear 0s infinite;
                }


                .title-box {
                    position: relative;
                    max-width: 80%;
                    margin: 0 auto;
                    text-align: center;
                    z-index: 2;
                    .title {
                        text-transform: uppercase;
                        font-weight: bold;
                    }

                    hr {
                        max-width: 100%;
                        margin-left: auto;
                        margin-right: auto;

                    }
                    @media (min-width: 600px) {
                        max-width: 50%;
                    }      
                    a,.category {
                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }    

                }

                &.first {
                    .mockup-container {
                        .container {
                            margin: -100px auto -100px auto;

                            @media (min-width: 600px) {
                                margin: -100px auto -100px auto;
                            }

                            @media (min-width: 840px) {
                                margin: -4.5em auto;                                
                            }
                        }

                        .background-asset {
                            top: 45px;
                        }
                    }
                }
            }
        }

        .modals {
            color: black;
            .modal-dialog {
                margin-top: calc(var(--nav-height) + 50px);
            }
        }
    }
}