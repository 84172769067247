&.is-blog {
    flex-direction: column;

    background-image: url("/img/polar-lights-bg.webp");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    // Repeated Every Section -- !! KEEP DRY !!
    .blog {
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        // align-items: flex-start;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        padding: 0;
        background: linear-gradient(0deg, $dlcweb-color-secondary, transparent, $dlcweb-color-secondary);

        @media (min-width: 1200px) {
            min-height: calc(100vh - var(--nav-height));
        }


        .blog-entries {
            list-style: none;
            padding: 0;
            margin: 0;
        } 
        
    }

    .card {
        max-width: 90vw;
        margin: 0 auto;
        border-radius: 5px 5px 0 0 ;
        color: $dlcweb-color-secondary;

        .section {
            text-align: center;
            border-bottom: 1px solid #000;
            padding-bottom: 1em;
        }

        .card-img {
            max-height: 300px;
            overflow: hidden;
            img {
                object-fit: cover;
            }
        }

        
        .card-body {
    
            .card-title {
                color: $dlcweb-color-bluesky;
                
                .title {
                    font-weight: bold;
                    text-align: center;
                }
            }

            .description-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                .description {
                    max-width: 66%;
                    font-size: smaller;
                    color: $dlcweb-color-purple;

                }

                .rating {
                    max-width: 30%;
                    margin-left: auto;

                    .stars {
                        letter-spacing: 0;
                        font-size: small;
                        color: $dlcweb-color-bluesky;
                    }

                    .reviews-count {
                        letter-spacing: 0;
                        font-size: small;
                        text-align: center;
                    }
                }

                @media screen and (min-width:768px) {
                    
                }
            }
        }
    }
    
}