

// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


$custom-dark: #000;
$custom-dark-alt: #666;

$dlcweb-color-primary: #fff;
$dlcweb-color-secondary: #000;

$dlcweb-color-blue: #1e2f89;
$dlcweb-color-bluesky: #00d3b8;
$dlcweb-color-purple: #a469e7;

$dlcweb-text-bluesky-dark: #00988E;
$dlcweb-text-bluesky-800: #009D92;
$dlcweb-text-bluesky-900: #00A297;

// Menu, Sidebar-navigation
$menu-padding-left: 10%;

// bootstrap selector colors
$primary: $dlcweb-color-bluesky;
 
// barba js
$barba-transition-timer: 0.5s;
$new-home-timer: $barba-transition-timer*2;

$nav-show-delay: 1s;
// --barba-transition-timer: $barba-transition-timer;

$filler-image-max-height : 20vh;
// --filler-image-max-height: $filler-image-max-height;