button,input, input[type=submit],a {
    &.button-primary {
        text-align: center;
        font-size: larger;
        letter-spacing: 1px;
        font-family: 'Poppins', 'sans serif';
        color: inherit;
        width: 100%;
        padding: 1em 2em;
        border: solid $custom-dark 2px;
        // border-radius: 3px;
        &:hover {
            border: solid white 2px;
            background-color: $custom-dark;
            color: white;
        }
    }
}
.dark {
    button,input, input[type=submit],a {
        &.button-primary {
            background-color: rgba(0,0,0,0);
            outline: none;
            text-decoration: none;
            text-transform: uppercase;
            color: white!important;
            border-color: white!important;
        
            &:hover {
                background-color: white!important;
                color: $custom-dark!important;
                border-color: $custom-dark!important;
        
            }
        }
    }
}

a {
    &.icon-link {

        i {
            color: $custom-dark;
            margin-right: 0.2rem;
        }
        letter-spacing: 1px;
        font-family: 'Poppins', 'sans serif';
        border-bottom: solid $custom-dark 2px;
        transition: border 0.3s ease-in-out;
        // border-radius: 3px;
        margin-bottom: 0.5em;
        &:hover {
            border-bottom: solid white 2px;
        }
    }
}
.dark {
    a {
        &.icon-link {
    
            i {
                color: white;
                margin-right: 0.2rem;
            }
            color: white;
            border-bottom: solid rgba(0,0,0,0) 2px;
            // border-radius: 3px;
            &:hover {
                border-bottom: solid white 2px;
            }
        }
    }
}